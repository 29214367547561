<template>
  <div class="container self-poster">
    <search-bar :show-group="showGroup" @on-search="onSearch" />
    <div v-if="posterList.length > 0" class="poster-list">
      <van-list
        :finished="isFinished"
        :offset="200"
        finished-text="没有更多了"
        @load="fetchPosterList"
      >
        <van-row gutter="8">
          <van-col v-for="item in posterList" :key="item.id" span="12">
            <div class="preview-item">
              <van-icon
                v-if="item.isCommon"
                class="common-type"
                :name="CommonType"
                size="40"
                @click="setTempCommon(item)"
              />
              <van-image
                width="100%"
                height="182px"
                fit="cover"
                :src="item.sampleImgUrl"
                @click.stop="onEdit(item)"
              />
              <p class="preview-title">{{ item.title }}</p>
            </div>
          </van-col>
        </van-row>
      </van-list>
    </div>
    <div v-else class="previewList">
      <empty-error />
    </div>
    <buttom-bar v-model="activeIndex" />
  </div>
</template>

<script>
import { getPosterList, setCommon } from '@/api/modules/poster'

import EmptyError from '@/components/EmptyError.vue'
import CommonType from '@/assets/img/common_type.png'
import SearchBar from './components/search-bar.vue'
import ButtomBar from './components/buttom-bar.vue'
export default {
  components: {
    EmptyError,
    SearchBar,
    ButtomBar
  },

  data() {
    return {
      CommonType,
      posterList: [],
      isLoadPoster: false,
      isFinished: false,
      activeIndex: 1,
      showGroup: true,
      params: {
        catId: '',
        groupList: [],
        isCommon: false,
        pageIndex: 1,
        pageSize: 10
      }
    }
  },

  watch: {
    activeIndex(val) {
      if (val === 2) {
        this.showGroup = false
        Object.assign(this.params, this.$options.data().params, { isCommon: true })
        this.fetchPosterList()
      }
      else {
        this.params.isCommon = false
        this.showGroup = true
        this.fetchPosterList()
      }
      this.posterList = []
    }
  },

  created() {
    this.fetchPosterList()
  },

  methods: {
    onEdit(item) {
      this.$router.push({
        path: `/selfPoster/${this.$route.params.tenant}/edit`,
        query: {
          id: item.id
        }
      })
    },

    onSearch(params) {
      Object.assign(this.params, params)
      this.posterList = []
      this.fetchPosterList()
    },

    setTempCommon(item) {
      setCommon({
        commonSetting: 'CANCEL',
        materialId: item.id,
        type: '20711'
      })
        .then(res => {
          if (res.code === 0) {
            item.isCommon = !item.isCommon
            this.$toast('取消成功')
          }
          else {
            this.$toast(res.data.msg)
          }
        })
        .catch(() => {
          this.$toast('设置失败，请稍后重试')
        })
    },

    /**
     * 获取海报列表
     */
    async fetchPosterList() {
      if (this.isLoadPoster) return
      this.isLoadPoster = true

      const res = await getPosterList(this.params)
      this.isLoadPoster = false
      if (res.code === 0) {
        this.posterList.push(...res.data.dataList)
        if (this.posterList.length >= res.data.page.totalCount) {
          this.isFinished = true
        }
        else {
          this.params.pageIndex = this.params.pageIndex + 1
          this.isFinished = false
        }
      }
      else {
        this.$toast(res.msg)
      }
    }
  }
}
</script>

<style scoped lang="less">
.self-poster {
  height: calc(100vh - 114px);
  overflow: hidden;
  margin-top: 64px;
  .poster-list {
    overflow-y: auto;
    height: 100%;
    padding: 8px;
    box-sizing: border-box;

    .preview-item {
      position: relative;
      margin-bottom: 16px;
      background-color: #fff;
      border-radius: 6px;
      font-size: 0;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      overflow: hidden;
      .error {
        height: 100px;
        border: 1px solid #fff;
      }
      .preview-title {
        padding: 12px;
        font-size: 12px;
        line-height: 16px;
        word-break: break-all;
        margin: 0;
      }
      .common-type {
        position: absolute;
        top: -2px;
        right: 8px;
        z-index: 999;
      }
    }
  }
}
</style>
