<template>
  <div class="buttom-bar">
    <van-tabbar v-model="activeIndex" @change="onChangeTab">
      <van-tabbar-item
        v-for="item in tabBarList"
        :key="item.id"
        :name="item.id"
        :title="item.title"
        :icon="item.icon"
      >
        <span>{{ item.title }}</span>
        <template #icon="props">
          <i
            style="font-size: 20px"
            class="iconfont"
            :class="props.active ? item.icon2 : item.icon1"
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    value: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      activeIndex: 1,
      tabBarList: [
        {
          title: '全部',
          url: 'all',
          id: 1,
          type: 1,
          icon1: 'iconjuxing',
          icon2: 'iconjuxing11'
        },
        {
          title: '常用',
          url: 'common',
          id: 2,
          type: 2,
          icon1: 'iconchangyongweixuanzhong',
          icon2: 'iconchangyongyixuanzhong1'
        }
      ]
    }
  },

  created() {
    this.activeIndex = this.value
  },

  methods: {
    onChangeTab(index) {
      this.$emit('change', index)
    }
  }
}
</script>
