<template>
  <div class="empty-error">
    <div v-show="emptyErrorType == 'empty'" class="empty-error-content">
      <img src="../assets/img/common/empty.png" alt="" />
      <div class="text">暂无数据</div>
    </div>
    <div v-show="emptyErrorType == 'error'" class="empty-error-content">
      <!-- <img style="width: 188px" src="../assets/img/common/empty.png" alt=""> -->
      <div class="text">网络异常</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyErrorType',
  props: {
    emptyErrorType: {
      type: String,
      default: 'empty'
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped lang="less">
.empty-error {
  .empty-error-content {
    text-align: center;
    padding-top: 100px;
    img {
      width: 100px;
    }
    .text {
      font-size: 14px;
      color: #252525;
      margin-top: 40px;
    }
  }
}
</style>
