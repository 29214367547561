import service from '@/api'

/**
 * 获取海报列表
 */
export const getPosterList = params =>
  service.post('/dapi/b-access-layer/v1/access/template-center/posterTemplate/list', params)

/**
 * 获取海报详情
 */
export const getPosterDetail = id =>
  service.get(`/dapi/b-access-layer/v1/access/template-center/posterTemplate/detail?id=${id}`)

/**
 * 创建海报
 */
export const createPoster = data =>
  service.post('/dapi/b-access-layer/v1/access/content-management/content/picture/create', data)

/**
 * 设置或取消常用
 */
export const setCommon = data =>
  service.post('/dapi/b-access-layer/v1/access/template-center/v1/content/material/common_setting', data)
